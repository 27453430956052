// Root
html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Misc
button {
  font-family: inherit;
}

dl dt {
  font-weight: bold;
}

hr {
  border: none;
  background-color: $color-workflow-lightgrey;
  height: 1px;
  margin: 1rem 0;
}

table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

table.striped tr:nth-of-type(2n) {
  background-color: $color-workflow-bg-secondary;
}

td,
th {
  vertical-align: middle;
  padding: 1.2rem 0.4rem;
}

thead {
  border-bottom: 2px solid $color-workflow-lightgrey;
}

tfoot {
  border-top: 2px solid $color-workflow-lightgrey;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

img {
  max-width: 100%;
}

fieldset {
  border: 1px solid $color-workflow-lightgrey;
}

iframe {
  border: 0;
}

ul {
  list-style: none;
}
