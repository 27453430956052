@import 'variables';

@mixin typog-h1 {
  font-size: 7.2rem; // 72px
  line-height: 9rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h1,
.typog-h1 {
  @include typog-h1;
}

@mixin typog-h2 {
  font-size: 6rem; // 60px
  line-height: 7.2rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h2,
.typog-h2 {
  @include typog-h2;
}

@mixin typog-h3 {
  font-size: 4.8rem; // 48px
  line-height: 6rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h3,
.typog-h3 {
  @include typog-h3;
}

@mixin typog-h4 {
  font-size: 3.6rem; // 36px
  line-height: 4.4rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h4,
.typog-h4 {
  @include typog-h4;
}

@mixin typog-h5 {
  font-size: 3rem; // 30px
  line-height: 3.8rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h5,
.typog-h5 {
  @include typog-h5;
}

@mixin typog-h6 {
  font-size: 2.4rem; // 24px
  line-height: 3.2rem;
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}
//h6,
.typog-h6 {
  @include typog-h6;
}

// Regular Fonts

@mixin typog-xl {
  font-size: 2rem; // 20px
  line-height: 3rem;
}
.typog-xl {
  @include typog-xl;
}

@mixin typog-lg {
  font-size: 1.8rem; // 18px
  line-height: 2.8rem;
}
.typog-lg {
  @include typog-lg;
}

@mixin typog-md {
  font-size: 1.6rem; // 16px
  line-height: 2.4rem;
}
.typog-md {
  @include typog-md;
}

@mixin typog-sm {
  font-size: 1.4rem; // 14px
  line-height: 2rem;
}
.typog-sm {
  @include typog-sm;
}

@mixin typog-xs {
  font-size: 1.2rem; // 12px
  line-height: 1.8rem;
}
.typog-xs {
  @include typog-xs;
}

@mixin typog-xxs {
  font-size: 1rem; // 10px
  line-height: 1.5rem;
}
.typog-xxs {
  @include typog-xxs;
}

.typog-size-90p {
  font-size: 0.9em;
}

.typog-size-80p {
  font-size: 0.8em;
}

.typog-size-70p {
  font-size: 0.7em;
}

.typog-size-60p {
  font-size: 0.6em;
}

.typog-size-50p {
  font-size: 0.5em;
}

a {
  color: $color-workflow-link;
  text-decoration: none;

  &:hover:not(.button) {
    opacity: 0.75;
  }
}

p {
  margin-top: 0;
}

// Font Weights

@mixin font-weight-normal {
  font-weight: 400;
}
.font-weight-normal {
  @include font-weight-normal;
}
@mixin font-weight-medium {
  font-weight: 500;
}
.font-weight-medium {
  @include font-weight-medium;
}
@mixin font-weight-semi {
  font-weight: 600;
}
.font-weight-semi {
  @include font-weight-semi;
}
@mixin font-weight-bold {
  font-weight: 700; // <strong>
}
.font-weight-bold {
  @include font-weight-bold;
}

@mixin font-weight-bolder {
  font-weight: 900;
}
.font-weight-bolder {
  @include font-weight-bolder;
}

// Font Direction

.di-rtl {
  direction: rtl;
}

.di-ltr {
  direction: ltr;
}

// util classes

.loader-text {
  @include font-weight-normal;
  @include typog-sm;
  text-align: center;
}

.font-size-inherit {
  font-size: inherit;
}

