@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@keyframes radialGradientIn {
  from {
    background: radial-gradient(circle, transparent 100%);
  }
  to {
    background: radial-gradient(
                    circle,
                    transparent 40%,
                    rgba(255, 255, 255, 0.1) 55%,
                    rgba(255, 255, 255, 0.3) 75%,
                    rgba(255, 255, 255, 0.5) 90%
    );
  }
}

@keyframes radialGradientOut {
  from {
    background: radial-gradient(
                    circle,
                    transparent 40%,
                    rgba(255, 255, 255, 0.1) 55%,
                    rgba(255, 255, 255, 0.3) 75%,
                    rgba(255, 255, 255, 0.5) 90%
    );
  }
  to {
    background: radial-gradient(circle, transparent 100%);
  }
}
