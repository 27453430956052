.card {
  padding: 1rem 2rem;
  border-radius: 4px;
  background: $color-workflow-bg;
  // box-shadow: 0 1px 3px $color-workflow-grey;
}

.card p:last-child {
  margin: 0;
}

.card header > * {
  margin-top: 0;
  margin-bottom: 1rem;
}
