@for $i from 0 through 8 {
  // Margin classes
  .m-#{$i} {
    margin: $i * $spacing-unit !important;
  }

  .mt-#{$i} {
    margin-top: $i * $spacing-unit !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $spacing-unit !important;
  }

  .ml-#{$i} {
    margin-left: $i * $spacing-unit !important;
  }

  .mr-#{$i} {
    margin-right: $i * $spacing-unit !important;
  }

  .mx-#{$i} {
    margin-left: $i * $spacing-unit !important;
    margin-right: $i * $spacing-unit !important;
  }

  .my-#{$i} {
    margin-top: $i * $spacing-unit !important;
    margin-bottom: $i * $spacing-unit !important;
  }

  // Padding classes
  .p-#{$i} {
    padding: $i * $spacing-unit !important;
  }

  .pt-#{$i} {
    padding-top: $i * $spacing-unit !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * $spacing-unit !important;
  }

  .pl-#{$i} {
    padding-left: $i * $spacing-unit !important;
  }

  .pr-#{$i} {
    padding-right: $i * $spacing-unit !important;
  }

  .px-#{$i} {
    padding-left: $i * $spacing-unit !important;
    padding-right: $i * $spacing-unit !important;
  }

  .py-#{$i} {
    padding-top: $i * $spacing-unit !important;
    padding-bottom: $i * $spacing-unit !important;
  }
  //gap for items in flex
  .gap-#{$i} {
    gap: $i * $spacing-unit !important;
  }
}
