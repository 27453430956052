@mixin skeleton-background {
  background: var(--color-on-background-muted);
  border-radius: 4px;
}

@mixin skeleton-background-gradient {
    background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0),
                    rgba(255, 255, 255, 0.4),
                    rgba(255, 255, 255, 0)
    );
}

/* PrimeNg Skeleton */
#web-app .p-component {
  &.p-skeleton {
    @include skeleton-background;
    &:after {
      @include skeleton-background-gradient;
    }
  }
}

/* Finxone Skeleton */
.skeleton_100,
.skeleton_75,
.skeleton_50,
.skeleton_25 {
  @include skeleton-background;
  position: relative;
  height: 2rem;
  display: inline-block;
  &:after {
    content: "";
    animation: skeleton-loading 1.2s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    @include skeleton-background-gradient;
  }
  &.skeleton_100 {
    width: 100px;
  }
  &.skeleton_75 {
    width: 75px;
  }
  &.skeleton_50 {
    width: 50px;
  }
  &.skeleton_25 {
    width: 25px;
  }
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
